/* About Section Styles  */

#aboutPage {
	overflow: hidden;
	min-height: 100vh;
	padding: 2.3rem 2rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("../svgs/about-page-bg.svg");
}

#aboutPage .container {
	gap: 1rem;
	padding: 0;
	display: flex;
	margin-top: 13vh;
	flex-direction: column;
}

/* Text highlight styles  */

.highlight {
	color: var(--light-clr);
}

/* 1st About section  */

.about-content-1 {
	gap: 8%;
	display: flex;
	padding: 0.5rem;
	margin: 2.8rem 0;
	flex-direction: row;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

.about-left {
	width: 50%;
	display: flex;
	margin-right: 1rem;
	flex-direction: column;
	justify-content: center;
}

.about-text {
	width: 100%;
}

.about-btns {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

.resume-btn {
	width: 175px;
	padding: 0.8rem 0.8rem;
	border-radius: 8px;
	font-size: 1.2rem;
	margin: 1rem;
	text-align: center;
	font-weight: bold;
	color: var(--light-clr);
	background-color: var(--blue-clr);
	box-shadow: 10px 10px 10px black;
}

.resume-btn:hover {
	box-shadow: none;
	color: var(--light-clr);
	background-color: rgb(0, 94, 255);
}

.download-resume {
	width: 75px;
}

/* About Me Picture  */

.about-img-box {
	z-index: 2;
	width: 280px;
	display: flex;
	height: 280px;
	margin-top: 1rem;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: all 300ms ease-in-out;
	border: 1px solid var(--lght-clr);
}

.about-img-box::after {
	z-index: -1;
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	transform: translate(21px, 21px);
	transition: all 300ms ease-in-out;
	border: 1px dashed var(--light-clr);
}

.about-img-box::before {
	content: "";
	z-index: -1;
	width: 100%;
	height: 100%;
	position: absolute;
	transform: translate(-21px, -21px);
	transition: all 300ms ease-in-out;
	border: 1px dashed var(--light-clr);
}

.about-pic {
	width: 100%;
	height: 100%;
	filter: grayscale(65%);
	transition: all 300ms ease-in-out;
}

.about-pic:hover {
	filter: grayscale(0%);
}

.about-img-box:hover::before,
.about-img-box:hover::after {
	box-shadow: inset 0px 0px 10px rgb(61, 129, 255),
		0px 0px 14px rgb(121, 106, 255);
}

.about-img-box:hover::before {
	transform: translate(-16px, -16px);
}

.about-img-box:hover::after {
	transform: translate(16px, 16px);
}

/* About Section Two  */

.about-content-2 {
	gap: 8%;
	width: 100%;
	display: flex;
	padding: 0.5rem;
	margin: 2.8rem 0;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.about-2-img {
	width: 325px;
	height: 325px;
	transition: all 300ms ease-in-out;
}

.about-2-img:hover {
	filter: contrast(110%);
}

.about-2-text {
	width: 60%;
}

/* The about line  */

.about-bottom-line {
	width: 50%;
	height: 2px;
	color: white;
	margin: 1.5rem auto;
	background-color: white;
}

.about-content-3 {
	gap: 8%;
	width: 100%;
	display: flex;
	padding: 0.5rem;
	margin-top: 2.5rem;
	align-items: center;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	#aboutPage {
		padding: 1.8rem 1.5rem;
	}

	#aboutPage .container {
		margin-top: 10vh;
	}

	.about-content-1 {
		gap: 2rem;
		margin: 1.8rem 0;
		flex-direction: column;
	}

	.about-left {
		width: 95%;
		margin-right: 0;
	}

	.about-text {
		width: 100%;
		text-align: center;
	}

	.resume-btn {
		font-size: 1rem;
		max-width: 150px;
		max-height: 70px;
		word-wrap: break-word;
	}

	.download-resume {
		max-width: 75px;
	}

	/* About Me Image  */

	.about-img-box {
		width: 220px;
		height: 220px;
	}

	/* About 2nd Section  */

	.about-content-2 {
		flex-direction: column-reverse;
		margin: 1.8rem 0;
	}

	.about-2-img {
		width: 280px;
		height: 280px;
	}

	.about-2-text {
		width: 95%;
	}

	/* About 3rd Section  */

	.about-content-3 {
		margin: 0;
	}
}

/* Galaxy Phone  */

@media screen and (max-width: 280px) {
	.about-btns {
		gap: 1rem;
	}

	.resume-btn {
		margin: 0;
	}

	.about-img-box {
		width: 180px;
		height: 180px;
	}

	/* About Content 2  */
	.about-2-img {
		width: 200px;
		height: 200px;
	}
}
