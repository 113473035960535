.one-project {
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	background-color: rgba(68, 0, 227, 0.166);
	margin: 0.8rem;
	width: 30%;
	min-width: 360px;
	max-width: 400px;
	min-height: 620px;
	max-height: 625px;
	overflow: hidden;
	transition: all 750ms ease;
	border: 1px solid transparent;
}

.one-project:hover {
	transform: translate(-2.8px, -3.8px);
	background-color: rgba(0, 5, 27, 0);
	border-bottom: 1px solid var(--purp-clr);
}

.one-project-img {
	width: 100%;
	min-height: 240px;
	max-height: 275px;
}

.one-project-bottom {
	padding: 0.7rem;
	width: 100%;
}

.one-project-title {
	font-size: 1.75rem !important;
	text-align: center;
	padding-bottom: 0.8rem;
	margin: 0 !important;
}

.one-project-text {
	text-align: center;
	font-size: 1rem !important;
}

/* Project Stack  */

.one-project h4 {
	color: var(--purp-clr);
	text-align: center;
}

.one-project-tech .skill-anchor img {
	width: 32px;
	margin: 0.35rem;
}

.one-project-hr {
	color: rgb(255, 255, 255);
	width: 80%;
	margin: 0 auto;
}

/* Project Stack  */

.one-project-techs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.one-project-tech {
	filter: grayscale(1);
	transition: all 300ms ease;
}

.one-project-tech:hover {
	filter: grayscale(0);
	cursor: pointer;
}

/* Project Buttons  */

.one-project-btns {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	.one-project {
		width: 100%;
		min-width: auto;
		max-width: auto;
		min-height: auto;
		max-height: none;
		height: auto;
		margin: 0.8rem auto;
	}

	.one-project-img {
		width: 100%;
	}

	.one-project-bottom {
		padding: 0.5rem;
		width: 100%;
	}

	.one-project-title {
		padding-bottom: 0.3rem;
		font-size: 1.2rem !important;
	}

	.one-project-text {
		text-align: center;
		font-size: 0.95rem !important;
		margin-bottom: 0.2rem;
	}

	.one-project h4 {
		font-size: 1.15rem;
	}

	.one-project-tech .skill-anchor img {
		width: 27.5px;
		margin: 0.3rem;
	}
}
