/* Contact Section Styles  */

.contact-section {
	background-image: url("../svgs/contact-bg.svg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	min-height: 100vh;
	padding: 5.5rem 0;
}

.contact-section .container {
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.contact-title {
	color: var(--purp-clr);
}

.contact-title span {
	color: var(--light-clr);
}

.contact-info {
	font-size: 1.1rem;
	margin: 1rem 0;
}

.contact-info span {
	color: var(--light-clr);
}

.contact-wrapper {
	width: 100%;
	min-height: 500px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5%;
	margin: 4.5rem 0;
}

.contact-wrapper-left {
	width: 50%;
	height: 100%;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-wrapper-right {
	width: 50%;
	height: 100%;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cform {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #00132f41;
	width: 100%;
	min-height: 400px;
	padding: 1.5rem;
	border-radius: 8px;
	color: var(--light-clr);
	border: 1px solid var(--blue-clr);
	box-shadow: 4px 11px 30px black;
	transition: all 300ms ease;
}

.cform-label {
	width: 89%;
	padding-bottom: 0.5rem;
	font-size: 1.1rem;
}

.cform-name,
.cform-email,
.cform-message {
	width: 90%;
	outline: none;
	margin-bottom: 0.8rem;
	border-radius: 2px;
	border: none;
	padding: 0.5rem;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, 0.421);
	color: var(--light-clr);
	border: 1px solid var(--sub-blue-clr);
}

.cform-name:focus,
.cform-email:focus,
.cform-message:focus {
	border: 1px solid var(--blue-clr-hover);
	background-color: rgba(0, 0, 0, 0.678);
}

.cform-name:hover,
.cform-email:hover,
.cform-message:hover {
	background-color: rgba(0, 0, 0, 0.678);
}

.cform-message {
	resize: none;
	min-height: 5rem;
	max-height: 8rem;
}

.cform-send {
	width: 30%;
	outline: none;
	border: none;
	border-radius: 8px;
	padding: 0.3rem 0.6rem;
	background-color: var(--blue-clr);
	color: var(--light-clr);
	font-size: 1.2rem;
}

.cform-send:hover {
	background-color: var(--blue-clr-hover);
}

.cform-send:disabled {
	background-color: var(--blue-clr-hover);
	opacity: 0.25;
	cursor: unset;
}

/* Right Side Contact Icons  */

.contact-wrapper-right h3 {
	color: var(--purp-clr);
}

.contact-icons {
	min-height: 400px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	background-color: #0704215b;
	width: 100%;
	padding: 1.5rem 2.15rem;
	border-radius: 8px;
	color: var(--light-clr);
	border: 1px solid var(--purp-clr);
	box-shadow: 4px 11px 30px black;
}

.contact-icons a {
	margin: 1rem;
}

.contact-icons i {
	color: var(--purp-clr);
	font-size: 3.5rem;
	margin: 1rem;
	transition: all 200ms ease;
}

.contact-icons i:hover {
	color: var(--light-clr);
	filter: drop-shadow(0 0 0.75rem rgb(232, 105, 255));
}

/* Contact Section Bottom  */

.contact-bottom {
	width: 100%;
	padding: 5.5rem 0 0 0;
	text-align: center;
	margin: 0;
	margin-bottom: -2.5rem;
}

.contact-bottom i {
	border: 2px solid transparent !important;
	padding: 4px 9px;
	border-radius: 5px;
	color: var(--blue-clr);
	font-size: 1.8rem;
	transition: all 500ms ease;
}

.contact-bottom i:hover {
	cursor: pointer;
	box-shadow: inset 0px 0px 5px rgb(89, 147, 255),
		0px 0px 14px rgb(87, 140, 255);
	color: var(--light-clr);
	border: 2px solid rgb(169, 213, 255) !important;
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	.contact-section {
		padding: 3.5rem 1.1rem !important;
	}

	.contact-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-direction: column;
		gap: 5rem;
	}

	.contact-info {
		font-size: 1.1rem;
		margin: 0.5rem 0;
	}

	.contact-wrapper-left,
	.contact-wrapper-right {
		width: 100%;
	}

	/* Form  */

	.cform-label {
		width: 100%;
		text-align: center;
		padding-bottom: 0.75rem;
		font-size: 1.2rem;
	}

	.cform-send {
		border-radius: 6px;
		border: none;
		font-size: 1.1rem;
		text-align: center;
	}

	.contact-icons {
		justify-content: space-evenly;
		align-items: center;
		height: auto;
	}

	.contact-icons i {
		font-size: 2.75rem;
		margin: 0.8rem;
	}
}

/* Galaxy Fold  */

@media screen and (max-width: 280px) {
	.cform-send {
		padding: 0.2rem;
	}
}

/* border Glow  */

.border-glow {
	transition: all 300ms ease;
	animation: glow-border 4800ms ease;
}

/* Animations  */

@keyframes glow-border {
	0% {
		box-shadow: none;
	}

	50% {
		box-shadow: inset 0px 0px 10px rgb(61, 129, 255),
			0px 0px 14px rgb(121, 106, 255);
	}

	100% {
		box-shadow: none;
	}
}
