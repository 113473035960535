/* Core CSS File  */

/* Open Sans Google Font  */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

/* Global Variables  */

:root {
	/* Primary Colors  */
	--blue-clr: #1e70ff;
	--blue-clr-hover: #5b94ff;
	/* Secondary Colors  */
	--purp-clr: #9f32ff;
	--purp-clr-hover: #6000b4;
	/* Contrast Colors  */
	--dark-clr: #040010;
	--light-clr: #fff;
	/* sub colors  */
	--light-sub-clr: #bababa;
	--sub-purp-clr: #070421;
	--sub-blue-clr: #00132f;
}

/* Universal Resetter  */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Global Setters  */

body {
	font-family: "Open Sans", sans-serif;
	width: 100%;
	background: var(--sub-purp-clr);
}

li {
	list-style: none;
}

a {
	text-decoration: none !important;
}

p {
	color: var(--light-sub-clr);
	font-size: 1.2rem !important;
}

/* Headers  */

h2 {
	font-size: 2.8rem !important;
	padding: 0.2rem 0;
	color: var(--light-clr);
	font-weight: bolder;
	border-bottom: 2px solid var(--blue-clr);
	width: fit-content;
	margin-bottom: 2rem !important;
}

h3 {
	font-size: 2.2rem !important;
	color: var(--blue-clr);
	font-weight: bold;
	margin-bottom: 0.8rem !important;
}

/* Each Section  */

section {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 3.5rem 2rem;
	min-height: 75vh;
	overflow: hidden;
}

/* Global classes  */

.invert {
	filter: invert(1) !important;
}

.hide {
	display: none;
}

/* scroll bar  */

/* width */
::-webkit-scrollbar {
	width: 9.5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgb(70, 119, 255);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(0, 61, 184);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(0, 71, 194);
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	h1 {
		font-size: 2.3rem !important;
	}

	h2 {
		margin: 1rem auto;
		text-align: center;
		font-size: 2rem !important;
		padding: 0.1rem 0.3em;
	}

	h3 {
		margin: 1rem auto;
		text-align: center;
		font-size: 1.65rem !important;
		padding: 0 0.2em;
		color: var(--blue-clr);
		font-weight: bold;
		margin-bottom: 0.8rem !important;
	}

	p {
		text-align: center;
		font-size: 1rem !important;
	}

	section {
		padding: 2.8rem 1.1rem !important;
	}
}
