/* Skills Section Styles  */

.skills-section {
	padding: 5rem 0;
	position: relative;
}

.skills-section .container {
	padding: 3em 0;
}

.skills-section-bg {
	background-image: url("../imgs/ailenbg.jpg");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -2;
	filter: saturate(80%);
}

.skills-slide-left {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: -1;
}

.skills-slide-right {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: -1;
}

.skills-section .container h3 {
	margin-top: 2.8rem;
}

/* Languages and Frameworks Wrapper  */

.skills-box-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	z-index: 2;
	border: 2px solid var(--blue-clr);
	background-color: rgba(0, 0, 0, 0.607);
	padding: 1rem;
	margin: 1.5rem 0 3.5rem 0;
	border-radius: 8px;
}

.skill {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80px;
	margin: 1rem 1.3rem;
	transition: all 100ms ease;
	text-align: center;
	border: 1px solid transparent;
}

.skill-anchor {
	width: 100%;
}

.skill:hover {
	border-bottom: 1px solid var(--blue-clr);
}

.skill:hover,
.skill:hover .skill-title {
	filter: saturate(300%);
	color: var(--light-clr);
}

.skill-img {
	width: 85%;
}

.skill-api {
	filter: saturate(900%);
}

.skill-title {
	text-align: center;
	width: 100%;
	color: var(--light-sub-clr);
	margin: 0.5rem 0;
	transition: all 100ms ease;
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	.skills-box-container {
		justify-content: space-evenly;
	}

	.skills-section .container h3 {
		color: var(--blue-clr-hover);
		margin-top: 2.8rem;
		background-color: rgba(0, 0, 0, 0.709);
		padding: 0.8rem;
		width: 100%;
		border-radius: 8px;
		font-size: 1.2rem;
	}

	.skill {
		width: 62px;
	}

	.skill-title {
		font-size: 1rem;
	}
}
