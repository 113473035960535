/* Footer Section Style  */

.footer-section {
	background-image: url("../svgs/footer.svg");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 35vh;
	position: relative;
}

.footer-section .container {
	z-index: 2;
}

.footer-links-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: flex-start;
}

.footer-link-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 1rem;
}

.footer-link-section h3,
.footer-logo {
	font-size: 1.65rem !important;
	font-weight: bold;
}

.footer-logo:hover {
	color: var(--blue-clr);
}

.footer-ul {
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.2rem;
}

.footer-ul li a {
	font-size: 1.1rem;
	font-weight: normal;
	color: var(--light-sub-clr);
}

.footer-ul li a:hover {
	color: var(--light-clr);
}

/* Reveal the background for the footer  */

.footer-layer {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-color: rgba(0, 25, 124, 0.109);
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	.footer-section .container {
		padding: 0;
	}

	.footer-link-section {
		text-align: center;
		justify-content: center;
		align-items: center;
		width: 50%;
		padding: 0.5rem 0.8rem;
	}

	.footer-link-section h3,
	.footer-logo {
		font-size: 1.25rem !important;
		margin: 0 0 0.8rem 0 !important;
	}

	.footer-ul {
		justify-content: center;
		align-items: center;
	}

	.footer-ul li a {
		font-size: 1rem;
	}
}
