/* Project Section Styles  */

.project-section {
	background-image: url("../svgs/new-project-bg.svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: 20%;
	width: 100%;
	min-height: 100vh;
}

.project-section .container {
	padding: 3rem 0;
}

.project-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

/* Primary Cards  */

.project-primary {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	margin-bottom: 4rem;
}

.project-prime-card {
	max-width: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 4.5rem 0;
	padding: 0.8rem;
	align-items: center;
	gap: 6%;
	transition: all ease;
}

.reverse {
	flex-direction: row-reverse;
	text-align: end;
}

.project-prime-middle {
	text-align: start;
}

.project-prime-title {
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
	color: var(--purp-clr);
	border-bottom: 1px solid var(--light-sub-clr);
	width: fit-content;
}

.project-prime-title-sub {
	font-size: 1.4rem;
	font-style: italic;
	color: var(--blue-clr-hover);
}

.project-prime-card p {
	color: var(--light-sub-clr);
	margin-bottom: 1rem;
	font-size: 1.1rem;
}

.project-prime-card-left {
	width: 600px;
}

.project-prime-card-right {
	padding: 0 0.5rem;
	width: 50%;
	height: 100%;
}

.project-prime-thumbnail {
	border-radius: 8px;
	width: 100%;
	height: 100%;
	box-shadow: 4px 11px 30px black;
}

/* Project Card Technologies  */

.project-prime-tech {
	user-select: none;
	margin: 1rem 0;
}

.project-prime-tech-title {
	font-size: 1.1rem;
	color: var(--purp-clr);
	margin: 0.5rem 0;
}

.tech-icon {
	width: 35px;
	margin: 0.5rem;
	transition: all ease-in;
	filter: grayscale(1);
}

.tech-icon:hover {
	filter: grayscale(0);
}

/* Project buttons  */

.project-prime-btns {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.project-prime-btn {
	padding: 0.6rem 0.9rem;
	border-radius: 2px;
	margin: 1rem 0.5rem;
	color: var(--light-clr);
}

.project-prime-btn:hover {
	color: var(--light-clr);
}

.deploy-btn {
	background-color: var(--purp-clr);
}

.deploy-btn:hover {
	background-color: var(--purp-clr-hover);
}

.github-btn {
	background-color: var(--blue-clr-hover);
}

.github-btn:hover {
	background-color: var(--blue-clr);
}

/* View More Projects Styles  */

.project-more {
	margin: 3rem 0;
	padding: 2.5rem 0;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	min-height: 30vh;
}

.project-more-left {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	height: 100%;
}

.project-more-right {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45%;
	height: 100%;
}

.project-more-right .check-out-my-projects-message {
	position: absolute;
	top: -15%;
	left: -20%;
	width: 60%;
	color: var(--light-clr);
	border: 1px solid var(--purp-clr-hover);
	padding: 0.8rem;
	border-radius: 8px;
	z-index: 2;
	background-color: rgba(192, 0, 192, 0.095);
}

.view-all-projects {
	font-size: 1.4rem;
	background-color: var(--blue-clr);
	color: var(--light-clr);
	padding: 0.8rem 1.2rem;
	margin: 1rem auto;
	border-radius: 8px;
	text-align: center;
	box-shadow: 8px 5px 30px black;
	outline: none;
	transition: all 50ms ease;
}

.view-all-projects:hover,
.view-all-projects:hover a {
	outline: none;
	color: white;
	background-color: rgb(0, 94, 255);
	box-shadow: none;
}

.man-laptop {
	z-index: 3;
	width: 100%;
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	/* cards  */

	.project-primary {
		margin: 1rem 0 3rem 0;
	}

	.project-prime-card {
		max-width: 90%;
		min-height: 200px;
		flex-direction: column;
		margin: 2.5rem 0 4rem 0;
		padding: 0.3rem;
		gap: 0;
	}

	.project-prime-middle {
		text-align: center;
	}

	.project-prime-title {
		font-size: 1.8rem;
		margin: 1rem auto;
		color: var(--purp-clr);
		border-bottom: 1px solid var(--light-sub-clr);
		width: fit-content;
	}

	.project-prime-tech-title {
		font-size: 1.4rem;
	}

	.project-prime-card p {
		color: var(--light-sub-clr);
		margin-bottom: 1rem;
		font-size: 1.15rem;
		text-align: center;
	}

	.project-prime-card-left {
		width: 95%;
	}

	.project-prime-card-right {
		text-align: center;
		padding: 0.3rem 0;
		width: 100%;
		height: 80%;
	}

	.project-prime-thumbnail {
		border-radius: 10px;
		width: 100%;
		height: 100%;
	}

	/* Project Buttons  */

	.project-prime-btns {
		justify-content: center;
	}

	/* View More Projects  */

	.project-more {
		height: auto;
		margin: 4rem 0 1rem 0;
		justify-content: center;
		gap: 2rem;
	}

	.project-more-right .check-out-my-projects-message {
		top: -125px;
		width: 100%;
		border: 1px solid var(--purp-clr-hover);
		padding: 0.3rem;
		font-size: 0.9rem;
		max-height: 130px;
	}

	.view-all-projects {
		font-size: 1.2rem;
		background-color: var(--blue-clr);
		color: var(--blue-clr-hover);
		padding: 0.5rem 0.9rem;
		margin: 1rem auto;
		color: var(--light-clr);
	}

	.man-laptop {
		width: 100%;
	}
}

/* Galaxy Mobile Media Query  */

@media screen and (max-width: 320px) {
	.project-more-right .check-out-my-projects-message {
		top: -150px;
		width: 135%;
	}
}
