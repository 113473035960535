/* Navbar Styles  */

.navbar-container {
	position: fixed;
	top: 0;
	width: 100%;
	background: linear-gradient(to bottom, #010101 15%, transparent);
	z-index: 10;
	transition: all 800ms ease-in;
}

.navbar-full-dark {
	background: rgba(0, 0, 0, 0.871) !important;
}

.navbar-container .container {
	display: flex;
	flex-direction: row;
	padding: 1rem;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.logo {
	width: 25%;
	font-size: 1.5rem;
	font-weight: bolder;
	color: var(--blue-clr);
}

.logo:hover {
	color: var(--blue-clr-hover);
}

/* The List */

.navbar-ul {
	font-weight: bold;
	margin: auto 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}

.navbar-item a {
	color: white;
	margin: 0;
	font-size: 1.2rem;
	border-bottom: 1px solid transparent;
	background: linear-gradient(
			to right,
			rgba(100, 200, 200, 0),
			rgba(100, 200, 200, 0)
		),
		linear-gradient(to right, var(--blue-clr), var(--blue-clr));
	background-size: 100% 0.1em, 0 0.1em;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	transition: background-size 300ms ease;
}

.navbar-item a:hover {
	background-size: 0 0.1em, 100% 0.1em;
	color: var(--blue-clr-hover);
	cursor: pointer;
}

.navbar-item a:focus {
	background-size: 0 0.1em, 100% 0.1em;
	color: var(--blue-clr-hover);
}

.nav-active-link {
	background: linear-gradient(
			to right,
			rgba(100, 200, 200, 0),
			rgba(100, 200, 200, 0)
		),
		linear-gradient(to right, var(--blue-clr), var(--blue-clr));
	background-size: 100% 0.1em, 0 0.1em;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	transition: background-size 300ms ease;
	background-size: 0 0.1em, 100% 0.15em;
}

/* Media Query  */

@media screen and (max-width: 993px) {
	.container {
		max-width: 930px !important;
	}
}
