/* Animations  */

/* Reveal From the bottom  */

.reveal {
	position: relative;
	transform: translateY(150px);
	opacity: 0;
	transition: 1.2s all ease;
}

.reveal.active {
	transform: translateY(0);
	opacity: 1;
}

/* Reveal from the left  */

.revealLeft {
	position: relative;
	transform: translateX(-150px);
	opacity: 0;
	transition: 1.2s all ease;
}

.revealLeft.activeLeft {
	transform: translateX(0);
	opacity: 1;
}

/* Reveal from the right  */

.revealRight {
	position: relative;
	transform: translateX(150px);
	opacity: 0;
	transition: 1.2s all ease;
}

.revealRight.activeRight {
	transform: translateX(0);
	opacity: 1;
}
