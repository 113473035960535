/* More Projects Page Styles  */

.more-projects-page {
	background-image: url("../svgs/projects-page.svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 1rem;
	min-height: 100vh;
	position: relative;
	z-index: 1;
}

.more-projects-filter {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	z-index: -1;
}

.more-projects-empty-space {
	height: 20vh;
}

.more-projects-page h1 {
	font-size: 3.2rem;
	font-weight: bold;
	color: var(--blue-clr);
	margin-bottom: 1.5rem;
}

.more-projects-project-container {
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.398);
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 1.5rem;
	margin: 4rem 0;
}

/* Show More button  */

.show-more-projects-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.show-more-projects {
	width: 45%;
	padding: 0.75rem 1rem;
	margin: 1.5rem auto;
	font-size: 1.2rem;
	border-radius: 8px;
	background-color: #6000b468;
	color: var(--light-clr);
	transition: all 250ms ease;
	border: none;
	outline: none;
}

.show-more-projects:hover {
	cursor: pointer;
	width: 47.5%;
	padding: 0.75rem 1rem;
	margin: 1.5rem auto;
	font-size: 1.2rem;
	border-radius: 8px;
	background-color: #9f32ff70;
}

/* Media Query (Mobile Size)  */

@media screen and (max-width: 830px) {
	.more-projects-empty-space {
		height: 15vh;
	}

	.more-projects-page {
		background-image: url("../svgs/phone-projects-page.svg");
	}

	.more-projects-page h1 {
		text-align: center;
	}

	.more-projects-sub-text {
		color: #fff;
		font-size: 1.1rem !important;
	}

	.more-projects-project-container {
		flex-direction: column;
		padding: 1.1rem;
	}

	/* Show More Projects Button  */

	.show-more-projects {
		width: 95%;
	}

	.show-more-projects:hover {
		width: 95%;
	}
}
